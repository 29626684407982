@keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes draw {
    from {
      stroke-dashoffset: 2000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  
  
  .fadeInUp {
    animation: fadeInUp 1s ease forwards;
  }

  .star-field {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .borders {
    height: 100%;
    width: 100%;
    position: fixed;
    inset: 0;
    padding: 1.125rem 4.1666666667vw 3rem;
    pointer-events: none
}

.borders-border {
    height: 100%;
    width: 100%
}

.borders-border svg {
    height: 100%;
    width: 100%;
    -webkit-mask-image: radial-gradient(at center bottom,transparent 40%,#000 60%);
    mask-image: radial-gradient(at center bottom,transparent 40%,#000 60%)
}

.borders-border svg line,.borders-border svg path,.borders-border svg polyline {
    stroke-width: .075rem;
    animation: draw 4s forwards;
}


.borders-border svg line,.borders-border svg path {
    opacity: .16
}

@media(min-width: 768px) {
    .borders {
        padding:1.125rem 1.125rem 3rem
    }

    .borders-border svg {
        -webkit-mask-image: radial-gradient(at center bottom,transparent,#000);
        mask-image: radial-gradient(at center bottom,transparent,#000)
    }
}

.swiper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.description-fade {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.description-visible {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
