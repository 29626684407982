.header {
  position: fixed;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  pointer-events: none;
  z-index: 50;
  display: flex;
  justify-content: space-between;
}
  
.menuButtonWrapper {
  aspect-ratio: 240/45;
  width: 13.75rem;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: var(--lmp-primary-100);
}

.menuButtonWrapperInner {
  aspect-ratio: 240/45;
  width: 13.75rem;
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 2;
  color: var(--lmp-secondary-100);
}
  
  
.menuIcon {
  z-index: 50;
  font-size: 24px; 
  color: black; 
}
  
.menuOverlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  color: var(--lmp-primary-100);
}

.menuLink {
  display: flex;
  flex-direction: column;
  gap: .4375rem;
  align-items: flex-end;
}

@supports (height:100svh) {
  .menuOverlay {
    height: 100svh;
  }
}

@media(min-width: 768px) {
  .menuButtonWrapper,
  .menuButtonWrapperInner {
      aspect-ratio:260/55;
      width: 16.25rem
  }

  .BottomMenu_logo__ixOyp {
      width: 5.125rem
  }
}



.buttonButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  font: inherit;
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0,0,0,0)
}

.buttonButton:hover {
  text-decoration: none
}

.buttonButton[disabled] {
  cursor: default;
  pointer-events: none
}


.menuBottom, 
.menuTop {
  position: fixed;
  width: 100%;
  inset-inline-start: 0;
  padding: 1.125rem;
  display: flex;
  justify-content: space-between;
  pointer-events: auto;
}

.menuTop {
  inset-block-start: 0;
}

.menuBottom {
  inset-block-end: 0;
  align-items: flex-end;
}

.menuCloseButton {
  display: inline-flex;
}

.menuCloseButtonIcon {
  aspect-ratio: 1/1;
  width: 1.5rem;
}

.menuCloseButtonIcon path {
  stroke: var(--lmp-primary-100);
}

.socialMenu {
  align-items: center;
}

.socialMenus {
  display: flex;
  gap: 1.125rem;
  color: var(--lmp-primary-100);
}

.soundButton {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: var(--lmp-primary-100);
}

.soundButtonInner {
  color: var(--lmp-secondary-100);
}

.menuBackdrop {
  background-color: white;
  opacity: 10%;
  --tw-blur: blur(12px);
  filter: var(--tw-blur);
  position: absolute;
  width: 20rem;
  height: 10rem;
  top: 40%;
  left: 5%;
  border-radius: 4rem;
}

.menuMiddle ul {
  color: white;
  font-size: 2rem;
  font-family: Roboto Mono,sans-serif;
  font-weight: 400;
}

@media (min-width:768px) {
  .menuMiddle ul {
    font-size: 8.9vw;
  }
}
@media (min-width:1146px) {
  .menuMiddle ul {
    font-size: 5.6vw;
  }
}

.soundButtonIcon path {
  stroke: rgba(var(--lmp-primary-100-rgb),.32)
}

.soundButtonIconInner path {
  stroke: rgba(var(--lmp-secondary-100-rgb),.32)
}

.headerSound {
  pointer-events: auto;
  padding: 1.125rem;
  position: relative;
}

.soundButtonIcon {
  height: 0.875rem;
  width: 0.875rem;
}

.soundLabel {
  font-size: 10px;
  line-height: 11px;
  font-family: Roboto Mono,arial,sans-serif;
  text-transform: uppercase;
}

.copyrightLabel {
  font-size: 10px;
  line-height: 11px;
  font-family: Roboto Mono,arial,sans-serif;
  text-transform: uppercase;
}

.bottomMenuCircle {
  min-width: 22.5rem;
  min-height: 22.5rem;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  background: currentcolor;
  border-radius: 50%
}

.bottomMenuBurger,
.bottomMenuBurgerInner {
  position: absolute;
  width: 2rem;
  height: auto;
  inset-inline-start: 50%;
  inset-block-start: 50%;
  transform: translate(-50%,-50%)
}


.bottomMenuBurger path {
  fill: var(--lmp-secondary-100)
}

.bottomMenuBurgerInner path {
  fill: var(--lmp-primary-100)
}

.menuButtonWrapper button,
.menuButtonWrapperInner button {
  pointer-events: auto;
}

.bottomMenuBurger,
.bottomMenuBurgerInner {
  pointer-events: none;
}

.soundButtonIcon path {
  transition: transform 0.5s ease;
}

.mousePosition {
  color: rgba(var(--lmp-primary-100-rgb),.32)
}
.mousePositionInner {
  color: rgba(var(--lmp-secondary-100-rgb),.32)
}

.headerMouse {
  padding: 1.125rem;
  position: relative;
}

@keyframes flickerOpacity {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.Link {
  opacity: 1;
}

.Link:hover {
  animation: flickerOpacity 0.2s;
  animation-fill-mode: forwards;
}

.socialLink:nth-child(1) svg,
.socialLink:nth-child(2) svg {
  height: 1.125rem;
  width: 1.125rem;
}
.socialLink:nth-child(3) svg,
.socialLink:nth-child(4) svg {
  height: 1.5rem;
  width: 1.5rem;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.fadeInAnimation {
  animation-name: fadeIn;
  animation-duration: .869s;
  animation-fill-mode: both;
}


.linkText::after {
  content: attr(data-text);
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  text-shadow: 2px 0 5px #40E0D0, -2px 0 5px #CD5C5C;
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: none;
}

.linkText:hover::after {
  animation: glitch 1s linear;
}

@keyframes glitch {
  2%, 8% {
    clip: rect(1px, 900px, 100px, 0);
    transform: translate(2px);
  }
  6% {
    clip: rect(1px, 100px, 100px, 0);
    transform: translate(-2px);
  }
  9% {
    clip: rect(1px, 900px, 100px, 0);
    transform: translate(0);
  }
}

.soundButtonMenu {
  padding: 0;
}

.textMenuLink {
  font-size: 10px;
  line-height: 11px;
  font-family: Roboto Mono,arial,sans-serif;
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .menuLink {
    flex-direction: row;
    gap: 1.125rem;
  }
}