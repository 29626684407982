.appWrapper {
    width: 100%;
    height: 100%;
} 
 
 .loader {
    padding: 2.5rem;
    background-color: #0d0d0d;
    height: 100%;
    width: 100%;
    position: fixed;
    color: var(--lmp-primary-100);
    z-index: 5;
}

@media (max-width: 768px) {
    .loader {
        padding: 1.8rem;
    }
}

.preLoader {
    height: 100%;
    width: 100%;
    position: relative;
    opacity: .5;
    padding: 1.125rem .25rem 2.25rem;
    white-space: pre-wrap;
    overflow: hidden;
}

@media (min-width: 768px) {
    .preLoader {
        opacity: 1;
        padding: 2.25rem;
    }
}
.label {
    font-size: 10px;
    line-height: 11px;
    font-family: Roboto Mono,arial,sans-serif;
    text-transform: uppercase;
}

.headingLoader {
    font-size: 14.81vw;
    font-family: Cycklone,arial,sans-serif;
    line-height: 75%;
}

.headingMain {
    font-size: 4.81vw;
    font-family: Roboto Mono,arial,sans-serif;
    line-height: 75%;
    transition: all;
    transition-duration: 300ms;
    transition-property: ease-in-out;
    text-align: center;
}


.subHeading {
    font-size: 1.5vw;
    font-family: Roboto Mono,arial,sans-serif;
    line-height: 75%;
    transition: all;
    transition-duration: 300ms;
    transition-property: ease-in-out;
    text-align: center;
    padding-top: 2rem;
}

@media (max-width: 768px) {
    .headingLoader {
        font-size: 25.81vw;
    }
    .headingMain {
        font-size: 6.81vw
    }
    .subHeading {
        font-size: 2.5vw;
    }
}

.preLoaderCounter {
    position: absolute;
    inset-inline-start: 50%;
    inset-block-start: 50%;
    transform: translate(-50%,-50%);
}


.counter {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute!important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    white-space: nowrap;
}

.fadeIn {
    animation: fadeInAnim 0.1s forwards;
}
    
@keyframes fadeInAnim {
    from { opacity: 0.5; }
    to { opacity: 1; }
}

.loadingWrapperContainer {
    width: 100%;
    height: 100%;
}

.scrollArrow {
    position: fixed;
    bottom: 12rem;
    color: rgb(255 255 255);
    left: 50%;
    transform: translate(-50%) rotate(90deg);
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    letter-spacing: .1em;
    white-space: nowrap;
    z-index: 3;
    cursor: pointer;
}
.scrollArrow::after,
.scrollArrow::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: 750ms cubic-bezier(.23,1,.32,1);
    -o-transition: 750ms cubic-bezier(.23,1,.32,1);
    -webkit-transition: 750ms cubic-bezier(.23,1,.32,1);
    transition: 750ms cubic-bezier(.23,1,.32,1);
}

.scrollArrow::after {
    left: calc(100% + 68px);
    width: 6px;
    height: 6px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: translateX(0) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -moz-transform: translateX(0) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -ms-transform: translateX(0) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -o-transform: translateX(0) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    transform: translateX(0) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
}

.scrollArrow::before {
    left: calc(100% + 15px);
    width: 60px;
    height: 1px;
    background-color: #fff;
    -webkit-transform: translateX(0) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -moz-transform: translateX(0) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -ms-transform: translateX(0) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -o-transform: translateX(0) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    transform: translateX(0) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
}

.scrollArrow:hover::after {
    -webkit-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -moz-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -ms-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    -o-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
    transform: translateX(15px) translateY(-50%) translateZ(0) rotate(-45deg) scale(1,1);
}

.scrollArrow:hover::before {
    -webkit-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -moz-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -ms-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    -o-transform: translateX(15px) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
    transform: translateX(15px) translateY(-50%) translateZ(0) rotate(0) scale(1,1);
}

.scrollArrow span {
    display: block;
    position: relative;
    overflow: hidden;
}

.scrollArrow span i {
    display: block;
    position: relative;
    font-style: normal;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transition: .3s;
    -o-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s ease;
}

.scrollArrow span i::after {
    content: attr(data-title);
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
}

.scrollArrow:hover span i {
    -webkit-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0) scale(1,1);
    -moz-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0) scale(1,1);
    -ms-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0) scale(1,1);
    -o-transform: translateX(0) translateY(-100%) translateZ(0) rotate(0) scale(1,1);
    transform: translateX(0) translateY(-100%) translateZ(0) rotate(0) scale(1,1);
}


@keyframes jumbo {
    from {
        background-position: 50% 50%, 50% 50%;
    }
    to {
        background-position: 350% 50%, 350% 50%;
    }
}

.jumbo {
    --stripes: repeating-linear-gradient(
        100deg,
        #fff 0%,
        #fff 7%,
        transparent 10%,
        transparent 12%,
        #fff 16%
    );
    --stripesDark: repeating-linear-gradient(
        100deg,
        #000 0%,
        #000 7%,
        transparent 10%,
        transparent 12%,
        #000 16%
    );
    --rainbow: repeating-linear-gradient(
        100deg,
        #60a5fa 10%,
        #e879f9 15%,
        #60a5fa 20%,
        #5eead4 25%,
        #60a5fa 30%
    );
    background-image: var(--stripesDark), var(--rainbow);
    background-size: 300%, 200%;
    background-position: 50% 50%, 50% 50%;

    filter: blur(10px) opacity(50%) saturate(200%);

    mask-image: radial-gradient(ellipse at 100% 0%, black 40%, transparent 70%);

    pointer-events: none;
}

.jumbo::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: var(--stripesDark), var(--rainbow);
    background-size: 200%, 100%;
    animation: jumbo 60s linear infinite;
    background-attachment: fixed;
    mix-blend-mode: difference;
}

.cursor {
    display: inline-block;
    margin-left: 5px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }

  @keyframes glitchAnimation {
    0%, 100% { opacity: 0; }
    10%, 30%, 50%, 70%, 90% { opacity: 1; }
    20%, 40%, 60%, 80% { opacity: 0.1; }
  }
  
  .glitchOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation: glitchAnimation 0.1s linear;
    pointer-events: none;
    display: none;
    z-index: 50;
  }