@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.footer {
    padding: 1rem;
    text-align: center;
    color: white;
    border-width: 1px;
    width: 90%;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.007);
    border-radius: 50%;
    border-top: 4px solid #ffffff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.inputField:focus ~ .inputLabel,
.activeLabel {
    top: -1rem !important;
    transform: translate(-0.5rem, -50%) scale(0.9);
}

.mainContactWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
}

.mainContactInner {
    display: flex;
    width: 100%;
    justify-content: center;
}

.contactFormContainer {
    padding-top: 2rem;
    width: 91.666667%;
}

@media (min-width: 768px) {
    .contactFormContainer {
      padding-bottom: 2rem;
    }
  }

.contactUsHeading {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.nameInputField,
.emailInputField,
.messageInputField {
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
    width: 100%;
}


@media (max-width: 768px) {
    .submitButton {
        height: 50px;
    }
    .messageInputField textarea {
        height: 75px;
    }
    .contactFormContainer {
        padding-top: 0;
    }
}

.nameInputField input,
.emailInputField input,
.messageInputField textarea {
    width: 100%;
    background-color: transparent;
    padding: .75rem;
    color: rgb(239 68 68 / var(--text-opacity));
    caret-color: #ef4444;
    transition-property: all .3s ease-in-out;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    border: 1px solid hsla(0,0%,100%,.2)
}

.nameInputField input::placeholder,
.emailInputField input::placeholder,
.messageInputField textarea::placeholder {
    display: none;
    opacity: 0;
}


.inputField:focus,
.emailInputField:focus,
.messageInputField:focus  {
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-color: rgb(239 68 68 / var(--text-opacity));
}

.messageInputField textarea {
resize: none;
}

.nameInputField label,
.emailInputField label,
.messageInputField label {
    pointer-events: none;
    position: absolute;
    left: var(--spacing-8);
    top: var(--spacing-1);
    user-select: none;
    padding: .2rem .5rem;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 300;
    transition: transform .3s ease,top .3s ease
}

.errorMessage {
    color: rgb(239 68 68 / var(--text-opacity));
}

.submitButton {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--color-red);
    padding: var(--spacing-24);
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: var(--color-black);
    transition: all .3s ease-in-out;
    box-shadow:0 0 49px rgba(255,162,162,.3);
}
.submitButton:hover {
    background-color: #fc7e7e;
}

.redBorder {
    border-color: rgb(239 68 68 / var(--border-opacity)) !important;
}