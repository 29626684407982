@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: Cycklone;
  src: url(/public/static/fonts/cycklone.05d57b08.woff2) format("woff2"),
  url(/public/static/fonts/cycklone.3a3954ee.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}


@font-face {
  font-family: Roboto Mono;
  src: url(/public/static/fonts/roboto-mono-regular.aa44fca3.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

:root {
  --lmp-primary-100: #fff;
  --lmp-primary-100-rgb: 255,255,255;
  --lmp-secondary-100: #000000;
  --lmp-secondary-100-rgb: 0,0,0;
  --color-black: rgb(10 9 13);
  --color-red: #fc6f6f;
  --spacing-1: 1px;
  --spacing--1: -1px;
  --spacing-8: .5rem;
  --spacing-16: 1rem;
  --spacing--16: -1rem;
  --spacing-24: 1.5rem;
  --spacing--24: -1.5rem;
  --text-opacity: 1;
  --border-opacity: 1;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d0d0d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2,
p {
  color: white;
}

section h2,
section p {
  color: black;
}

section h2 {
  font-weight: 600;
}